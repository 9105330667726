<template>
    <v-container>
        <div style="margin: 60px 0;"></div>
        <PrivacyPolicy />
    </v-container>
</template>

<script>
import PrivacyPolicy from '@/components/legal/PrivacyPolicy'

export default {
    components: {
        PrivacyPolicy
    }
}
</script>